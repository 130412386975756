<template>
  <div class="messageInfo" v-loading="loading">
    <div class="backBox" @click="toPage">
      {{ i18nData.to }} <i class="el-icon-right"></i>
    </div>
    <div class="messageBox"  v-if="!loading">
      <div class="title">
        <span>{{ messageDetail.message_title }}</span>
      </div>
      <div class="date">
        <span>{{ i18nData.time }}: </span
        ><span>{{ messageInfo.createtime | dateFormat }}</span>
      </div>
      <div class="content">
        <span v-html="messageDetail.message_content"></span>
      </div>
    </div>
    <div class="operation">
      <el-button
        @click="deletedMessage"
        type="primary"
        icon="el-icon-delete"
        plain
        >{{ i18nData.delete }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { dateFormat } from "./../common/Format.js";

export default {
  name: "myMessageInfo",
  components: {},
  data() {
    return {
      loading: true,
      id: "",
      messageInfo: [],
      messageDetail: {}
    };
  },
  created() {
    this.$store.state.myActiveIndex = this.$route.name;
    let query = this.$route.query;
    if (query) {
      this.id = query.id;
    }
    this.getInfo();
  },
  methods: {
    toPage() {
      this.$router.go(-1);
    },
    async getInfo() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          rec_id: this.id
        };
        let res = await this.$api.https.getMessageDetails(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        this.messageInfo = res.data || {};
        this.messageDetail = res.data.messagenotice || {};
        // this.$store.state.vuex__token__ = res.data.__token__;
        this.$nextTick(() => {
          this.loading = false;
        });
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async deletedMessage() {
      let params = {
        rec_id: this.id
      };
      let res = await this.$api.https.deletedMessage(params);
      if (!res.code) {
        this.$message({
          message: res.msg,
          type: "error",
          offset: 40
        });
        return;
      }
      this.$message({
        message: res.msg,
        type: "success",
        offset: 40
      });
      this.$router.go(-1);
    }
  },
  filters: {
    dateFormat
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #0495f3;
.messageInfo {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  text-align: left;
  padding: 20px 0 0 40px;
  .backBox {
    padding: 0 0 20px;
    align-self: flex-end;
    font-size: 14px;
    color: $vue_color;
    display: flex;
    align-items: center;
    cursor: pointer;
    i {
      margin-left: 10px;
      font-size: 20px;
    }
  }
  .messageBox {
    box-sizing: border-box;
    padding: 40px 20px;
    width: 100%;
    border: 1px solid #dcdcdc99;
    border-radius: 5px;
    .title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }
    .date {
      font-size: 14px;
      text-align: center;
      margin: 10px 0;
    }
    .content {
      box-sizing: border-box;
      padding: 20px;
      background-color: #e7e7ef;
      border-radius: 5px;
      min-height: 10vh;
      font-size: 14px;
    }
  }
  .operation {
    padding: 20px 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    ::v-deep .el-button--primary.is-plain {
      width: 120px;
      background-color: #ffffff;
      border-color: $vue_color;
      color: $vue_color;
    }
    ::v-deep .el-button--primary.is-plain:focus,
    .el-button--primary.is-plain:hover {
      background-color: $vue_color;
      color: #ffffff;
      transition: 0.5s;
    }
    ::v-deep .el-button--primary.is-plain.is-disabled,
    .el-button--primary.is-plain.is-disabled:active,
    .el-button--primary.is-plain.is-disabled:focus,
    .el-button--primary.is-plain.is-disabled:hover {
      background-color: #ebeef5;
      border-color: #c0c4cc;
      color: $vue_color;
    }
  }
}
</style>
